import { Stack, Typography } from "@mui/material";

const OwnerCard = ({ image, title, description, reverse, name }: any) => {
  return (
    <Stack
      direction={{ xs: "column-reverse", sm: reverse ? "row-reverse" : "row" }}
      alignItems="center"
      justifyContent="center"
      gap={{ xs: 2, md: !reverse ? 0 : 2 }}
      px={2}
      position="relative"
      pt={1}
      bgcolor={!reverse ? "white" : "inherit"}
    >
      <Stack flex={0.7} textAlign={{ xs: "center", sm: "left" }} spacing={2}>
        <Typography
          variant="h5"
          fontWeight="bold"
          fontFamily="monospace"
          fontStyle={"italic"}
        >
          {title.toUpperCase()}
        </Typography>
        <Typography
          variant="h5"
          fontWeight="bold"
          fontFamily="cursive"
          color="secondary.main"
          fontStyle={"italic"}
        >
          {name}
        </Typography>
        <Typography fontStyle="italic" variant="caption" pb={"10px"}>
          {description}
        </Typography>
      </Stack>

      <img
        loading="lazy"
        src={image}
        alt={`owner ${title}`}
        style={{
          objectFit: "cover",
          width: "100%",
          maxWidth: "300px", // Set a max-width for the image
          transform: reverse ? "scaleX(-1)" : "none",
        }}
      />
    </Stack>
  );
};

export default OwnerCard;
