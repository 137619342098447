import { Stack, Typography } from "@mui/material";

const FounderCard = ({ image, title, description, name }: any) => {
  return (
    <Stack
      alignItems={"center"}
      bgcolor={"white"}
      justifyContent={"center"}
      gap={2}
      p={2}
      textAlign={"center"}
    >
      <Typography
        variant="h5"
        fontWeight={"bold"}
        fontFamily={"monospace"}
        fontStyle={"italic"}
      >
        {title.toUpperCase()}
      </Typography>
      <img
        loading="lazy"
        src={image}
        alt={"owner"}
        style={{
          objectFit: "scale-down",
          width: "200px",
        }}
      />
      <Typography
        variant="h5"
        fontWeight={"bold"}
        fontFamily={"cursive"}
        fontStyle={"italic"}
      >
        {name}
      </Typography>

      <Typography
        fontStyle={"italic"}
        variant="caption"
        maxWidth={{ sm: "80%", md: "30%" }}
      >
        {description}
      </Typography>
    </Stack>
  );
};

export default FounderCard;
