import { Stack, Typography, Paper } from "@mui/material";

const ProductCard = ({ name, image }: { name: string; image: any }) => {
  return (
    <Stack>
      <Paper sx={{ padding: "10px" }}>
        <img
          loading="lazy"
          src={image}
          alt="randomImage"
          style={{ objectFit: "fill" }}
          width={"200px"}
          height={"180px"}
        />
      </Paper>
      <Typography variant="button" fontSize={"18px"} textAlign={"center"}>
        {name}
      </Typography>
    </Stack>
  );
};

export default ProductCard;
