import {
  Typography,
  TextField,
  Button,
  Box,
  Container,
  Stack,
} from "@mui/material";

const ContactUs = () => {
  return (
    <Container maxWidth="xs" disableGutters sx={{ mr: "auto" }}>
      <Box mb={2} alignSelf={"start"}>
        <Typography variant="h5" color="text.secondary" fontWeight={"bold"}>
          Just say Hello !
        </Typography>
        <Typography
          variant="caption"
          fontStyle={"italic"}
          color="text.secondary"
          gutterBottom
        >
          Let us know more about you !
        </Typography>
      </Box>
      <Stack spacing={1}>
        <TextField
          size="small"
          required
          fullWidth
          label="Your Name"
          color="info"
          variant="filled"
          InputLabelProps={{
            sx: {
              color: "text.secondary",
            },
          }}
        />
        <TextField
          size="small"
          required
          fullWidth
          label="Email"
          color="info"
          variant="filled"
          InputLabelProps={{
            sx: {
              color: "text.secondary",
            },
          }}
        />
        <TextField
          size="small"
          required
          fullWidth
          multiline
          rows={4}
          label="Message"
          color="info"
          variant="filled"
          InputLabelProps={{
            sx: {
              color: "text.secondary",
            },
          }}
        />
        <Button size="small" type="submit" color="info">
          Submit
        </Button>
      </Stack>
    </Container>
  );
};

export default ContactUs;
