import { Card, Box, Typography } from "@mui/material";

const RoundCard = ({ image, title }: { image: string; title: string }) => {
  return (
    <Box>
      <Card sx={{ p: 2, borderRadius: "50%", mb: 1 }}>
        <img
          src={image}
          alt={image}
          width={"140px"}
          height={"140px"}
          loading="lazy"
        />
      </Card>
      <Typography textAlign={"center"} fontFamily={"fantasy"}>
        {title}
      </Typography>
    </Box>
  );
};

export default RoundCard;
