import { Stack, Typography, Box } from "@mui/material";
import { owners } from "../assets";

const AboutUs = () => {
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      gap={5}
      justifyContent="center"
      padding={2}
    >
      <Typography flex={{ xs: 1, md: 0.5 }} lineHeight={2}>
        Welcome to "Lalchand Pedawala", a culinary legacy that spans over seven
        decades, enchancing taste buds since 1950. Nestled in the heart of
        Chirawa,we are a renowned and cherished  brand, celebrated for our
        signature delicacy – the timeless Peda. Our story begins with a passion
        for crafting exquisite sweets that not only tantalize the palate but
        also carry with them the essence of tradition and a rich cultural
        heritage. For generations, we have been dedicated to creating moments of
        sweetness and joy for our patrons. Beyond our delectable offerings, our
        shop is a hub of warmth and hospitality. Step into our welcoming space,
        where tradition meets modernity, and experience the delight of choosing
        from an array of sweets that reflect the diversity and richness of
        Indian confectionery.
      </Typography>
      <Box flex={{ xs: 1, md: 0.2 }}>
        <img
          loading="lazy"
          src={owners.late_owner}
          width={"100%"}
          alt="Grandfather"
        />
      </Box>
    </Stack>
  );
};

export default AboutUs;
