import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function CardComponent({
  image,
  name,
  description,
}: {
  image: any;
  name: string;
  description: string;
}) {
  return (
    <Card sx={{ maxWidth: 260 }}>
      <CardMedia
        component="img"
        alt="Image"
        loading="lazy"
        image={image}
        sx={{
          height: 210,
          textAlign: "center",
          width: 210,
          margin: "0 auto",
        }}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {name}
        </Typography>
        <Typography variant="body1" color="text.primary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
}
