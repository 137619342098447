import {
  Card,
  Stack,
  CardMedia,
  CardContent,
  Typography,
  Divider,
  Button,
} from "@mui/material";

const AddressCard = ({
  addressTitle,
  addressDescription,
  contactNumber,
  image,
  mapData,
}: {
  addressTitle: string;
  addressDescription: string;
  contactNumber: string;
  image: string;
  mapData?: string;
}) => {
  return (
    <Card sx={{ maxWidth: 500, p: 2, bgcolor: "white" }}>
      <Stack
        direction={{ xs: "column", sm: "row" }} // Change direction based on screen size
        gap={2}
        alignItems={{ xs: "center", sm: "flex-start" }} // Center on mobile, flex-start on larger screens
        justifyContent={"center"}
      >
        <Stack flex={1}>
          <CardMedia
            component="img"
            alt="Image"
            loading="lazy"
            image={image}
            sx={{
              height: { xs: 220, sm: 240 }, // Adjust height based on screen size
              textAlign: "center",
              alignSelf: "center",
              width: { xs: 220, sm: 240 }, // Take full width on all screen sizes
              marginBottom: { xs: 2, sm: 0 }, // Add margin bottom on mobile
            }}
          />
          <CardContent>
            <Typography gutterBottom variant="h6" component="div">
              {addressTitle}
            </Typography>
            <Typography variant="caption" color="text.primary">
              {addressDescription}
            </Typography>
            <Typography
              mt={2}
              display={"block"}
              variant="caption"
              color="text.primary"
            >
              {contactNumber}
            </Typography>
          </CardContent>
        </Stack>
        <Divider
          flexItem
          color="white"
          orientation={"vertical"} // Change orientation based on screen size
        />
        <Stack
          spacing={2}
          direction={"column"}
          flex={1}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <iframe
            title="googleMaps"
            aria-hidden={true}
            style={{
              borderRadius: "10px",
              overflow: "hidden",
            }}
            height={"300"}
            width="100%" // Take full width on all screen sizes
            // height={{ xs: 220, sm: 280 }} // Adjust height based on screen size
            src="https://maps.google.com/maps/place?q=place_id:ChIJOcjRZw85EzkRzzjcufdBNXM&t=&z=13&ie=UTF8&iwloc=&output=embed"
          ></iframe>

          <Button variant="contained" sx={{ width: "100%" }}>
            Get Directions
          </Button>
          <Button variant="outlined" sx={{ width: "100%" }}>
            Contact Us
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};

export default AddressCard;
