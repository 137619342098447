import { Stack, Typography } from "@mui/material";
import OwnerCard from "../components/OwnerCard";
import FounderCard from "../components/FounderCard";
import { owners as Owners } from "../assets";

const owners = [
  {
    image: Owners.owner,
    title: "Continuing the Tradition",
    name: "Rajneesh Rao",
    description: `
  Rajneesh Rao inherited his father's passion, taking the reins of Lalchand Pedawala. 
  With decades of experience and an unwavering commitment to quality, Rajneesh has expanded our offerings while staying true to our roots. 
  His dedication to preserving the essence of our sweets has made us a cherished destination for those seeking the authentic taste of Indian confectionery.

  In addition to his role as the leader of Lalchand Pedawala, Rajneesh is actively involved in community initiatives, supporting local causes and promoting the rich culinary heritage of India. 
  Under his guidance, we continue to innovate and bring joy to our customers, offering a delightful array of traditional and contemporary sweets.

  Join us on a journey of flavors and tradition as Rajneesh and the entire team at Lalchand Pedawala invite you to experience the sweetness of life!
`,
  },
  {
    image: Owners.co_owner,
    title: "The Next Generation",
    name: "Divam Rao",
    description: `
    Meet Divam Rao, the visionary behind the next chapter of Lalchand Pedawala. With a fresh perspective and innovative ideas, Divam is infusing modern flair into our time-honored recipes. His goal is to create sweets that harmoniously blend tradition with contemporary tastes, ensuring that "Lalchand Pedawala" remains a timeless symbol of sweetness for generations to come. Under his creative leadership, each confection becomes a symphony of flavors, weaving together the rich heritage of the past with avant-garde delights that push the boundaries of sweet indulgence. Divam's commitment to culinary excellence and a meticulous attention to detail promise an experience that transcends mere confectionery, inviting customers to savor not just the flavors but also the stories behind each delectable creation. Lalchand Pedawala, under Divam's guidance, is not just a sweet shop; it's a destination where tradition and innovation coalesce into a legacy that resonates with both the old and the new.`,
  },
];
const founder = {
  image: Owners.late_owner,
  title: "Founder's Vision",
  name: "Late Lalchand ji",
  description: `
  Late Lalchandji, our esteemed founder, was not just a sweet maker; he was a visionary who saw sweetness as an art form. Starting with a small shop and a grand dream, he laid the foundation for "Lalchand Pedawala".Late Lalchandji's commitment to using the finest ingredients and preserving traditional recipes set the stage for what we stand for today.his  dedication to quality and authenticity resonates in every  bite of oursweets, cherished by generations of customers. His legacy continues to inspire us as we strive to uphold the rich heritage of Lalchand Pedawala. Join us on this journey as we celebrate the timeless artistry of sweetness, crafted with love and passed down through the ages.`,
};

const AboutUs = () => {
  return (
    <Stack bgcolor={"background.default"} pt={2}>
      <Stack
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        py={2}
        spacing={2}
      >
        <Typography
          variant="h5"
          fontFamily={"monospace"}
          fontWeight={"bold"}
          sx={{ textTransform: "uppercase" }}
        >
          Our Legacy
        </Typography>
        <Typography variant="caption" maxWidth={{ sm: "auto", md: "50%" }}>
          A Sweet Symphony of Generations Welcome to "Lalchand Pedawala"where
          every sweet is a chapter in our story of tradition, craftsmanship, and
          family heritage. Since our humble beginnings in 1950, we have been
          crafting moments of sweetness that transcend time and resonate through
          generations.
        </Typography>
      </Stack>
      <FounderCard {...founder} />
      {owners.map((on, index) => (
        <OwnerCard key={on.title} {...on} reverse={index % 2 === 0} />
      ))}
    </Stack>
  );
};

export default AboutUs;
