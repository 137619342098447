import { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Stack,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Link,
} from "@mui/material";
import { NavLink } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
import Marquee from "react-fast-marquee";
import { Menu } from "@mui/icons-material";
import Headroom from "react-headroom";
import { logo } from "../assets";

export const links = [
  { path: "/", title: "Home" },
  { path: "/products", title: "Products" },
  { path: "/services", title: "Services" },
  { path: "/branches", title: "Branches" },
  { path: "/aboutus", title: "About Us" },
];

export const socialLinks = [
  { url: "www.facebook.com", title: "Facebook" },
  { url: "www.instagram.com", title: "Instagram" },
  { url: "www.twitter.com", title: "Twitter" },
];

const Header = () => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  function handleScrollDown() {
    window.scrollTo({
      top: 1900,
      behavior: "smooth",
    });
  }

  return (
    <Box>
      <Box bgcolor={"secondary.main"}>
        <Marquee>
          <Typography color={"text.secondary"} variant="caption">
            As per Customer demand and Convenience, we are pleased to inform you
            that we are able to deliver and provide our “Peda”,”Laddoo” and
            other sweets
          </Typography>
        </Marquee>
      </Box>

      <Stack
        bgcolor={"background.default"}
        padding={1}
        paddingY={0.5}
        direction={{ xs: "column", md: "row" }}
        justifyContent={"space-between"}
        alignItems={"center"}
        gap={2}
      >
        <Stack direction={"row"} gap={2}></Stack>
        <Typography
          fontFamily={"cursive"}
          color={"primary.main"}
          textAlign={"center"}
        >
          "Taste the Tradition: Lalchand Pedawala, Where Sweets Tell Stories
          Since 1950!"
        </Typography>

        <Stack direction={{ xs: "row", md: "column" }} gap={0.3}>
          {socialLinks.map((el) => (
            <SocialIcon
              key={el.title}
              url={el.url}
              style={{ width: 20, height: 20 }}
            />
          ))}
        </Stack>
      </Stack>
      <Headroom pinStart={140} style={{ zIndex: 100 }}>
        <AppBar position="static">
          <Toolbar sx={{ justifyContent: "space-between" }}>
            <Box display={{ xs: "none", md: "block" }}>
              <img
                loading="lazy"
                src={require("./../assets/logo.png")}
                width={"100px"}
                alt="Logo"
              />
            </Box>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleDrawerOpen}
              sx={{ marginRight: 2, display: { md: "none" }, marginLeft: 0 }}
            >
              <Menu />
            </IconButton>
            <Stack
              direction={"row"}
              spacing={2}
              display={{ xs: "none", md: "flex" }}
              onClick={(e) => e.preventDefault()}
            >
              {links.map((link) => (
                <Link
                  component={NavLink}
                  sx={{
                    color: "white",
                    textDecoration: "none",
                  }}
                  to={link.path}
                  // style={({ isActive }: any) => ({
                  //   textDecoration: isActive ? "underline" : "none",
                  // })}
                >
                  {link.title}
                </Link>
              ))}
              <Link
                sx={{
                  color: "white",
                  textDecoration: "none",
                }}
                onClick={handleScrollDown}
                href=""
              >
                Contact Us
              </Link>
            </Stack>
            <Box>
              <img src={logo} width={"100px"} alt="Logo" />
            </Box>
          </Toolbar>
        </AppBar>
        {/* Drawer for small screens */}
        <Drawer anchor="left" open={isDrawerOpen} onClose={handleDrawerClose}>
          <List>
            {links.map((link) => (
              <ListItem
                key={link.title}
                button
                onClick={handleDrawerClose}
                component={NavLink}
                to={link.path}
              >
                <ListItemText primary={link.title} />
              </ListItem>
            ))}
          </List>
        </Drawer>
      </Headroom>
    </Box>
  );
};

export default Header;
