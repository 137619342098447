import { Box } from "@mui/material";
import LocationName from "./LocationName";

const VerticalTimeline = ({ items }: { items: any }) => {
  return (
    <Box paddingX={20} paddingY={5}>
      {items.map((item: any, index: number) => {
        return (
          <LocationName
            name={item.name}
            inverse={index % 2 === 0}
            image={item.image}
          />
        );
      })}
    </Box>
  );
};

export default VerticalTimeline;
