import { Typography, Stack, Divider, Link, Container } from "@mui/material";
import { links, socialLinks } from "./Header";
import { SocialIcon } from "react-social-icons";
import { NavLink } from "react-router-dom";
import ContactUs from "./ContactUs";

const Footer = () => {
  return (
    <div id="footer">
      <Stack
        direction={{ xs: "column", md: "row" }}
        bgcolor={"primary.main"}
        color={"text.secondary"}
        padding={2}
        justifyContent={"space-between"}
        spacing={2}
      >
        <ContactUs />
        <Divider color="white" flexItem orientation={"vertical"} />
        <Divider color="white" flexItem orientation={"horizontal"} />

        <Container maxWidth="xs" disableGutters>
          <Stack spacing={2}>
            <Typography
              variant="h5"
              fontWeight={"bold"}
              color={"text.secondary"}
            >
              Useful Links
            </Typography>
            <Stack spacing={2} onClick={(e) => e.preventDefault()}>
              {links.map((link) => (
                <Link
                  component={NavLink}
                  sx={{
                    color: "text.secondary",
                    textDecoration: "none",
                    maxWidth: "fit-content",
                  }}
                  // style={({ isActive }: any) => ({
                  //   textDecoration: isActive ? "underline" : "none",
                  // })}
                  variant="caption"
                  to={link.path}
                >
                  {link.title}
                </Link>
              ))}
              <Link
                sx={{
                  color: "white",
                  textDecoration: "none",
                  maxWidth: "fit-content",
                }}
                variant="caption"
                href={""}
              >
                Contact Us
              </Link>
            </Stack>
          </Stack>
        </Container>

        <Divider color="white" flexItem orientation={"vertical"} />
        <Divider color="white" flexItem orientation={"horizontal"} />

        <Container disableGutters>
          <Stack spacing={4}>
            <Stack direction={"row"} gap={2}>
              <Stack spacing={2}>
                <Typography
                  variant="h5"
                  fontWeight={"bold"}
                  color={"text.secondary"}
                >
                  Contact
                  <br /> Information
                </Typography>
                <Typography color={"text.secondary"} variant="caption">
                  Lalchand Pedawala
                  <br />
                  Opp. Panchayat Samiti,
                  <br /> Station Road Chirawa, Jhunjhunu, <br />
                  Rajasthan  Mob. +91 9667591714
                </Typography>
              </Stack>
              <iframe
                loading="lazy"
                title="googleMaps"
                aria-hidden={true}
                style={{
                  borderRadius: "10px",
                  alignSelf: "end",
                }}
                width="100"
                height="100"
                id="gmap_canvas"
                src="https://maps.google.com/maps/place?q=place_id:ChIJOcjRZw85EzkRzzjcufdBNXM&t=&z=13&ie=UTF8&iwloc=&output=embed"
              ></iframe>
            </Stack>

            <Stack spacing={2}>
              <Typography
                variant="h5"
                fontWeight={"bold"}
                color={"text.secondary"}
              >
                Follow Us
              </Typography>
              <Stack direction={"row"} spacing={2}>
                {socialLinks.map((el, index) => (
                  <SocialIcon
                    key={index}
                    url={el.url}
                    style={{ width: 30, height: 30 }}
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Stack>
      <Stack direction={"row"} bgcolor={"secondary.main"} p={1}>
        <Typography variant="body2" color="white">
          &copy; 2023 Lanchand PedaWala
        </Typography>
      </Stack>
    </div>
  );
};

export default Footer;
