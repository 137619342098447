import peda_1 from "./products/peda_1.png";
import peda_2 from "./products/peda_2.png";
import peda_3 from "./products/peda_3.png";
import peda_4 from "./products/peda_4.png";
import peda_5 from "./products/peda_5.png";
import peda_6 from "./products/peda_6.png";

import image1 from "./sweets/sweet_1.jpg";
import image2 from "./sweets/sweet_2.jpg";
import image3 from "./sweets/sweet_3.jpg";
import image4 from "./sweets/sweet_4.jpg";

import rajasthan from "./images/rajasthan.png";
import punjab from "./images/pubjab.png";
import hariyana from "./images/hariyana.png";
import gujrat from "./images/gujrat.png";
import mumbai from "./images/mumbai.png";
import kolkata from "./images/kolkata.png";
import delhi from "./images/delhi.png";
import up from "./images/up.png";
import jaipur from "./images/jaipur.jpg";
import delhi_1 from "./images/delhi.jpg";

import shop_1 from "./images/shop_image_1.jpg";
import shop_2 from "./images/shop_image_2.jpg";
import shop_3 from "./images/shop_image_3.jpg";
import shop_4 from "./images/shop_image_4.jpg";
import shop_5 from "./images/shop_image_5.jpg";
import shop_6 from "./images/shop_image_6.jpeg";

import logo from "./logo.png";

import owner from "./owner/owner.png";
import co_owner from "./owner/co_owner.png";
import late_owner from "./owner/late_owner.jpg";

const products_small = {
  peda_1,
  peda_2,
  peda_3,
  peda_4,
  peda_5,
  peda_6,
};
const products_large = {
  peda_1: image1,
  peda_2: image2,
  peda_3: image3,
  peda_4: image4,
};

const owners = {
  owner,
  co_owner,
  late_owner,
};

const shop_large = {
  shop_1,
  shop_2,
  shop_3,
  shop_4,
  shop_5,
  shop_6,
};

const states = {
  rajasthan,
  punjab,
  gujrat,
  delhi,
  up,
  mumbai,
  kolkata,
  hariyana,
  jaipur,
  delhi_1,
};

export { states, shop_large, owners, products_large, products_small, logo };
