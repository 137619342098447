import ImageCarousel from "../components/ImageCarousal";
import { Stack, Divider, Chip, Box, Typography } from "@mui/material";
import Feature from "../components/Feature";
import AboutUs from "../components/AboutUs";
import ProductCard from "../components/ProductCard";
import { products_small, shop_large,products_large } from "../assets";
import sweet from "../assets/products/sweet.png";
import besan_ladoo from "../assets/products/besan_ladoo.png";
import featurespic from "../assets/images/features.jpg";
import featuresimage from "../assets/images/featuresimage.jpg";

const features = [
  {
    title: "Time-Honored Recipe",
    description:
      "Our pedas are crafted using a cherished family recipe passed down through generations, ensuring an authentic and unforgettable taste experience.",
  },
  {
    title: "Premium Quality Ingredients",
    description:
      "We use only the finest quality ingredients sourced from trusted suppliers to create our pedas, guaranteeing a superior taste and texture with every bite.",
  },
  {
    title: "Exquisite Flavors",
    description:
      " Indulge in a diverse array of flavors, from traditional classics like saffron and cardamom to innovative variations like chocolate, coconut, and pistachio, each bursting with rich flavor.",
  },
  {
    title: "Handcrafted Perfection",
    description:
      "Every peda is meticulously handcrafted by skilled artisans, showcasing their dedication to perfection and attention to detail in every aspect of the production process.",
  },
  {
    title: "Freshness Assured",
    description:
      " We take pride in preparing our pedas in small batches to ensure maximum freshness, delivering a delectable treat that melts in your mouth with every bite.",
  },
  {
    title: "Customizable Options",
    description:
      "Personalize your order for special occasions or preferences, whether it's for weddings, festivals, corporate events, or simply to satisfy your sweet cravings.",
  },
];
const products = [
  { name: "SPECIAL PEDA", image: products_large.peda_3, },
  { name: "PLAIN PEDA", image:  products_large.peda_1,  },
  

  { name: "MEWA LADDU", image: besan_ladoo},

  { name: "KESAR PEDA", image: sweet},
];
const images = [
 featurespic,
  shop_large.shop_4,
  shop_large.shop_3,
  shop_large.shop_2,
];

const Home = () => {
  return (
    <Stack spacing={4} sx={{ margin: "0 auto" }} bgcolor={"white"}>
      <ImageCarousel images={images} />
      <Stack padding={2} spacing={4}>
        <Divider>
          <Chip label="About Us" />
        </Divider>
        <AboutUs />
        <Divider>
          <Chip label="Features" />
        </Divider>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={5}
          justifyContent={"center"}
          paddingX={{ xs: 2, md: 10 }}
        >
          <Stack gap={3} flex={1}>
            {features.slice(0, 3).map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </Stack>
          <Box flex={1}>
            <img
              src={featuresimage}
              height={"400px"}
              width={"100%"}
              alt="Sweets"
            />
          </Box>
          <Stack flex={1} gap={3}>
            {features.slice(3, 6).map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </Stack>
        </Stack>
        <Stack textAlign={"center"} spacing={4}>
          <Typography variant="h4" fontWeight={"bold"}>
            BEST SELLERS MADE WITH LOVE
          </Typography>
          <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline", textUnderlineOffset: "5px" }}
          >
            SWEETS
          </Typography>
          <Stack
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"center"}
            gap={6}
          >
            {products.map((product) => (
              <ProductCard key={product.name} {...product} />
            ))}
          </Stack>
          {/* <Typography
            variant="h6"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline", textUnderlineOffset: "5px" }}
          >
            RAJASTHANI NAMKEEN
          </Typography>
          <Stack
            direction={"row"}
            justifyContent={"center"}
            gap={6}
            flexWrap={"wrap"}
          >
            {products.map((product) => (
              <ProductCard key={product.name} {...product} />
            ))}
          </Stack> */}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home;
