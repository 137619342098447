import { useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ImageCarousel = ({ images }: { images: string[] }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSelect = (index: number) => {
    setCurrentIndex(index);
  };

  return (
    <Carousel
      selectedItem={currentIndex}
      onChange={handleSelect}
      showThumbs={false}
      showStatus={false}
      dynamicHeight={true}
      autoPlay={true}
      swipeable={true}
      emulateTouch={true}
      showArrows={true}
      infiniteLoop={true}
    >
      {images.map((url, index) => (
        <div key={url}>
          <img src={url} alt={`carousel-${index}`} loading="lazy" />
        </div>
      ))}
    </Carousel>
  );
};

export default ImageCarousel;
