import { shop_large, states } from "../assets";
import AddressCard from "../components/AddressCard";
import { Stack } from "@mui/material";

const stateAddresees = [
  {
    city: "jaipur",
    image: states.jaipur,
    branches: [
      {
        addressTitle: "Lalchand Peda Wala, Chirawa",
        addressDescription:
          "Station Rd, opposite Panchayat Samiti, opp. Chirawa Road, Pujari Colony, Chirawa, Rajasthan 333026",
        contactNumber: "+91 9667591714",
        image: shop_large.shop_1,
      },
      {
        addressTitle: "Lalchand Peda Wala (Chirawa bypass branch)",
        addressDescription:
          "lalchand peda wala, Jhunjhunu, Ojtu, bypass, near, Chirawa, Rajasthan 333026",
        contactNumber: "+91 9667591714",
        image: shop_large.shop_6,
      },
      {
        addressTitle: "Lal chand peda Wala (salasar branch )",
        addressDescription:
          "Gate no.1, lal chand peda wala, Behind shiv murti bear, Salasar, Rajasthan 331506",
        contactNumber: "+91 9667591714",
        image: shop_large.shop_2,
      },
    ],
  },
  {
    city: "delhi",
    image: states.delhi_1,
    branches: [
      {
        addressTitle: "Lalchand Peda wala Chirwa Wale",
        addressDescription:
          "D-14 230, 231, Halar Rd, Pocket 14, Sector 8D, Rohini, New Delhi, Delhi, 110085",
        contactNumber: "+91 9667591714",
        image: shop_large.shop_3,
      },
    ],
  },
];

const Branches = () => {
  return (
    <Stack p={2} bgcolor={"#fff3d9"} alignItems={"center"}>
      {stateAddresees.map((item) => (
        <>
          <img src={item.image} width={"300px"} alt={item.city} />
          <Stack
            gap={2}
            flexWrap={"wrap"}
            direction={"row"}
            justifyContent={"center"}
          >
            {item.branches.map((el) => (
              <AddressCard key={el.addressTitle} {...el} />
            ))}
          </Stack>
        </>
      ))}
    </Stack>
  );
};

export default Branches;
