import CardComponent from "../components/CardComponent";
import { Stack, Divider, Chip, Typography } from "@mui/material";
import ImageCarousel from "../components/ImageCarousal";
import { products_large, products_small } from "../assets";
import RoundCard from "../components/RoundCard";
import peda_7 from "../assets/products/peda_7.png";
import peda_8 from "../assets/products/peda_8.png";
import peda_9 from "../assets/products/peda_9.png";
import peda_10 from "../assets/products/peda_10.png";
import peda_11 from "../assets/products/peda_11.png";
import peda_12 from "../assets/products/peda_12.png";
import peda_13 from "../assets/products/peda_13.png";
import peda_14 from "../assets/products/peda_14.png";
import peda_15 from "../assets/products/peda_15.png";
import peda_16 from "../assets/products/peda_16.png";
import peda_17 from "../assets/products/peda_17.png";
import gulabjamun from "../assets/products/gulabjamun.png";
import chamcham from "../assets/products/chamcham.png";
import besangatta from "../assets/products/besangatta.png";
import besan_ladoo from "../assets/products/besan_ladoo.png";
import ghujiya from "../assets/products/ghujiya.png";
import gevar from "../assets/products/gevar.png";
import sweet from "../assets/products/sweet.png";

const carousalImages = [
  products_large.peda_1,
  products_large.peda_4,
  products_large.peda_3,
  products_large.peda_2,
  
];

const topProducts = [
  { image: products_small.peda_1, title: "Malai gevar" },
  { image: products_small.peda_2, title: "Doda barfi" },
  { image: products_small.peda_3, title: "Moti-pak" },
  { image: products_small.peda_4, title: "Rasbhari" },
  { image: products_small.peda_5, title: "Kaju katli" },
  
];
const sweetsData = [
  {
    name: "Sada Peda",
    description: "Simple and delightful, a classic sweet.",
    image: products_small.peda_6,
  },
  {
    name: "Laddu",
    description: "Rich and flavorful with saffron infusion.",
    image: peda_7,
  },
  {
    name: "Kesar Pista",
    description: "Traditional laddoo made with pure desi ghee.",
    image: peda_8,
  },
  {
    name: "Laddoo Single Mewa",
    description: "Single-nut laddoo, a perfect treat.",
    image: peda_9,
  },
  {
    name: "Ladoo Double Mewa",
    description: "Double the joy with a mix of nuts.",
    image: peda_10,
  },
  {
    name: "Balu Shahi",
    description: "Crunchy and sweet, a royal delicacy.",
    image: peda_11,
  },
  {
    name: "Kalakand",
    description: "Milk delicacy with a hint of sweetness.",
    image:peda_12,
  },
  
  {
    name: "Namkeen Mathi, Matar, Kachori",
    description: "Savory treats for a perfect snack time.",
    image: peda_13,
  },
  {
    name: "Kaju Katli",
    description: "Cashew goodness in every bite.",
    image: products_small.peda_5,
  },
  {
    name: "Gajar Pak",
    description: "Carrot-based sweet, a winter specialty.",
    image: peda_14,
  },
  {
    name: "Gond-Pak/Laddoo",
    description: "Nutty and nutritious, a winter favorite.",
    image:peda_15,
  },
  {
    name: "Petha/Mathlu",
    description: "Soft and sweet, a classic North Indian sweet.",
    image: peda_16,
  },
  {
    name: "Samosa/Chips",
    description: "Crunchy snacks for the perfect tea-time.",
    image: peda_17,
  },
  {
    name: "Gulab Jamun",
    description: "Soft and spongy, soaked in sweet syrup.",
    image:gulabjamun,
  },
  {
    name: "Rasgulla",
    description: "Light and airy, a Bengali sweet delicacy.",
    image: products_small.peda_4,
  },
  {
    name: "Chamcham",
    description: "Colorful and delicious, a festive treat.",
    image: chamcham,
  },
  {
    name: "Besan Ghatta",
    description: "Gram flour delight, a unique sweet.",
    image:besangatta,
  },
  {
    name: "Rasbhari",
    description: "Juicy and sweet, a fruity indulgence.",
    image: products_small.peda_4,
  },
  {
    name: "Besan Laddoo",
    description: "Classic laddoo with a gram flour twist.",
    image:besan_ladoo,
  },
  {
    name: "Ghujiya",
    description: "Crispy and filled, a Holi speciality.",
    image:ghujiya,
  },
  {
    name: "Gevar",
    description: "Rajasthani sweet, crispy and soaked in sugar syrup.",
    image: gevar,
  },
  {
    name: "Malai Gevar",
    description: "Rich and creamy, a royal sweet from Rajasthan.",
    image: products_small.peda_1,
  },
  {
    name: "Doda Barfi",
    description: "A dense and delicious milk fudge.",
    image:products_small.peda_2,
  },
  {
    name: "Moti-Pak",
    description: "Pearl-shaped sweet, a unique delight.",
    image: products_small.peda_3,
  },
  {
    name: "Special (Pista) Peda",
    description: "Pistachio-infused peda for a special treat.",
    image:sweet,
  },
];

const Products = () => {
  return (
    <Stack spacing={2}>
      <ImageCarousel images={carousalImages} />
      <Stack p={2} spacing={{ xs: 4, sm: 4, md: 5 }}>
        <Divider>
          <Chip
            label="TOP PRODUCTS"
            variant="filled"
            color="primary"
            size="medium"
          />
        </Divider>
        <Stack
          direction={"row"}
          gap={{ xs: 3, md: 3 }}
          justifyContent={"center"}
          flexWrap={"wrap"}
          mt={2}
        >
          {topProducts.map((el) => (
            <RoundCard key={el.image} {...el} />
          ))}
        </Stack>

        <Typography variant="h4" fontWeight={"bold"} textAlign={"center"}>
          VARIETIES WE OFFER
        </Typography>
        <Stack
          direction={"row"}
          gap={{ xs: 3, md: 3 }}
          justifyContent={"center"}
          flexWrap={"wrap"}
          mt={2}
        >
          {sweetsData.map((el) => (
            <CardComponent key={el.name} {...el} />
          ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Products;
