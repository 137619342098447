import "./App.css";
import { Container, Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";



function App() {
  return (
    <>
   
    <Container maxWidth="xl" disableGutters>
      
      <Header />
      <Box minHeight={"100vh"} sx={{ backgroundColor: "background.default" }}>
        <Outlet />
      </Box>
      <Footer />
    </Container>
    </>
  );
}


export default App;
