import { Box, Typography, Stack } from "@mui/material";
import VerticalTimeline from "../components/VerticalTimeline";
import { states } from "../assets";

const services = [
  {
    name: "Rajasthan",
    image: states.rajasthan,
  },
  {
    name: "Punjab",
    image: states.punjab,
  },
  {
    name: "Haryana",
    image: states.hariyana,
  },
  {
    name: "Gujarat",
    image: states.gujrat,
  },
  {
    name: "Kolkata",
    image: states.kolkata,
  },
  {
    name: "Mumbai",
    image: states.mumbai,
  },
  {
    name: "Delhi",
    image: states.delhi,
  },
  {
    name: "Western U.P",
    image: states.up,
  },
];

const Services = () => {
  return (
    <Box paddingY={2}>
      <Stack
        textAlign={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        py={2}
        spacing={2}
      >
        <Typography
          variant="h5"
          fontFamily={"monospace"}
          fontWeight={"bold"}
          sx={{ textTransform: "uppercase" }}
        >
          Services
        </Typography>
        <Typography variant="caption" maxWidth={{ sm: "auto", md: "50%" }}>
          As per Customer demand and Convenience, we are pleased to inform you
          that we are able to deliver and provide our “Peda”,”Laddoo” and other
          sweets to following places.
        </Typography>
      </Stack>
      <VerticalTimeline items={services} />
      <Stack paddingX={10} paddingY={5}>
        <Typography>
          We provide Savamani(स्वामणि) on famous temples like Khatu Shyamji,
          Salasar Balaji,Rani Sati Jhunjhunu,Shakambhari Mata,Jeen Mata,Lohagral
          Ji,Do Jaati Dham Sikar,Choodi Dham,Ghodiwala Balaji,Kalayan Ji Temple
          Chirawa,Pandit Ganesh Narayan Ji(Bawalia Baba) Temple Chirawa and
          other temples on demand.
        </Typography>
      </Stack>
    </Box>
  );
};

export default Services;
