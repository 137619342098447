import { Stack, Typography, Box } from "@mui/material";

const LocationName = ({
  name,
  inverse,
  image,
}: {
  name: string;
  inverse?: boolean;
  image: any;
}) => {
  return (
    <Stack
      direction={{
        xs: "column",
        sm: inverse ? "row-reverse" : "row",
      }}
      justifyContent={"center"}
    >
      <Stack
        flex={1}
        alignItems={{ xs: "center", sm: !inverse ? "flex-end" : "flex-start" }}
        ml={{ xs: "0", sm: inverse ? "10px" : "-10px" }}
        mr={{ xs: "0", sm: !inverse ? "10px" : "-10px" }}
      >
        <img
          loading="lazy"
          src={image}
          width={"170px"}
          style={{ borderRadius: "50%" }}
          height={"170px"}
          alt="state"
        />
      </Stack>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={{ xs: "center", sm: inverse ? "right" : "left" }}
        borderColor={"secondary.main"}
        flex={1}
        alignSelf={{ xs: "center", sm: "auto" }}
        zIndex={10}
        width={{ xs: "auto", sm: "30%" }}
        borderRight={{ xs: "none", sm: inverse ? "2px solid" : "none" }}
        borderLeft={{ xs: "none", sm: !inverse ? "2px solid" : "none" }}
      >
        {!inverse ? (
          <>
            <Stack
              padding={1}
              border={"1px solid secondary.main"}
              borderRadius={50}
              margin={{ xs: 0, sm: "-18px" }}
              display={{ xs: "none", sm: "flex" }}
            >
              <Box
                height={"20px"}
                width={"20px"}
                borderRadius={"50%"}
                bgcolor={"secondary.main"}
              />
            </Stack>
            <Box
              height={"2px"}
              bgcolor={"secondary.main"}
              width={"100px"}
              display={{ xs: "none", sm: "block" }}
            />
            <Typography variant="h4">{name}</Typography>
          </>
        ) : (
          <>
            <Typography variant="h4" textAlign={{ xs: "center" }}>
              {name}
            </Typography>
            <Box
              height={"2px"}
              bgcolor={"secondary.main"}
              width={"80px"}
              display={{ xs: "none", sm: "block" }}
            />
            <Stack
              padding={1}
              border={"1px solid secondary.main"}
              borderRadius={50}
              margin={"-18px"}
              display={{ xs: "none", sm: "flex" }}
            >
              <Box
                height={"20px"}
                width={"20px"}
                borderRadius={"50%"}
                bgcolor={"secondary.main"}
              />
            </Stack>
          </>
        )}
      </Stack>
    </Stack>
  );
};

export default LocationName;
