import { createTheme } from "@mui/material";

// Define your custom color palette
const customColors = {
  primary: "#EB583E", // Change this to your preferred primary color
  secondary: "#424242",
  warning: "#ffdd66",
  background: "#fef9f3",
  paper: "#eeeeee",
};

// Create a theme with the custom color palette
const theme = createTheme({
  palette: {
    primary: {
      main: customColors.primary,
    },
    secondary: {
      main: customColors.secondary,
    },
    warning: {
      main: customColors.warning,
    },
    info: {
      main: customColors.background,
    },
    background: {
      default: customColors.background,
      paper: customColors.paper,
    },
    text: {
      primary: "#424242",
      secondary: "#fef9f3",
    },
    // Customize other palette options if needed
  },
  typography: {
    fontFamily: "inherit",
    allVariants: {
      color: "#424242",
    },
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#000000",
            textDecoration: "underline #000000",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {},
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          ":before": {
            borderBottom: "none",
          },
          ":hover": {
            backgroundColor: "rgba(247,247,247,0.6)",
            border: "none",
          },
          backgroundColor: "rgba(247,247,247,0.4)",
        },
      },
    },
  },
});

export default theme;
