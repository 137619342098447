import { Stack, Typography } from "@mui/material";

const Feature = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => {
  return (
    <Stack spacing={1} alignItems={"center"}>
      <Typography variant="subtitle1">{title}</Typography>
      <Typography variant="caption" textAlign={"center"}>
        {description}
      </Typography>
    </Stack>
  );
};

export default Feature;
